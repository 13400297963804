<template>
  <el-dialog title="审核" :visible.sync="examineVisible" :close-on-click-modal="false" width="600px" append-to-body :before-close="handleClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
      <el-form-item label="状态" prop="status">
        <el-select v-model="ruleForm.status" placeholder="请选择">
          <el-option label="通过" value="2"></el-option>
          <el-option label="不通过" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审核备注" prop="note">
        <el-input class="w230" :rows="4" type="textarea" placeholder="请输入备注" v-model="ruleForm.note"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { auditUnit } from "@/api/unitManage"
  export default {
    name: "examineDilog",
    props: {
      examineVisible: {
        type: Boolean,
        default: false
      },
      examineId: {
        default: ""
      }
    },
    data() {
      return {
        ruleForm: {
          status:"",
          note: ''
        },
        rules: {
          status: [{required: true,message: '请选择状态', trigger: 'change' }]
        }
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:examineVisible', false)
        this.resetForm('ruleForm')
      },
      submitForm(formName) {
        this.$emit('refreshCount')
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.auditUnitPost()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      async auditUnitPost() {

        let params = JSON.parse(JSON.stringify(this.ruleForm))
        params.id = this.examineId
        const res = await auditUnit(params)
        if(res.state === 0) {
          this.$message.success(res.data)
          //请求成功，刷新页面
          this.$emit('reloadPage')
          this.resetForm('ruleForm')
          this.$emit('refreshCount')
          this.$emit('update:examineVisible', false)

        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .w230 {
    width: 300px;
  }
  .info {
    margin-left: 130px;
    width: 300px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
  }
</style>
